export const navLang = window.navigator.userLanguage || window.navigator.language;
export const getLang = (lang = navLang) => {
    // check the split length to avoid errors
    const splitLang = lang.split('-');
    if (splitLang.length === 0) {
        return lang;
    }
    return splitLang[0];
};
export const isFrenchLang = (lang = navLang) => getLang(lang) === 'fr';
